import { isEmpty } from "lodash";
import http from "./authConfig";
import userService from "./userService";
import { Capacitor } from "@capacitor/core";
import { DataSnapshot, getDatabase, onValue, ref } from "firebase/database";
import { setUserToken } from "../redux/userReducer";

const TOKEN = "africatik_token";
const REFRESH_TOKEN = "africatik_refresh_token";
const LOGIN = "africatik_login";
const TOKEN_ID = "africatik_token_id";
const MOTIF = "africatik_motif";

class AuthApi {
  async refreshToken() {
    const refresh_token = await this.getRefreshToken();
    let rep = false;
    if (refresh_token) {
      await http
        .post("/auth/refresh", { refresh_token })
        .then((response) => response.data)
        .then((response) => {
          if (response.token) {
            rep = true;
            localStorage.setItem(TOKEN, response.token);
            localStorage.setItem(REFRESH_TOKEN, response.refresh_token);
          }
        })
        .catch((e) => console.warn("Error", e));
    }
    return rep;
  }

  async getMotif() {
    return localStorage.getItem(MOTIF);
  }
  async setMotif(value: string) {
    localStorage.setItem(MOTIF, value);
  }

  async removeMotif() {
    localStorage.removeItem(MOTIF);
  }

  async logout(dispatch?: any) {
    await localStorage.removeItem(TOKEN);
    await localStorage.removeItem(REFRESH_TOKEN);
    await localStorage.removeItem(TOKEN_ID);
    await localStorage.removeItem(LOGIN);
    if (typeof dispatch === "function") {
      dispatch(setUserToken(""));
    }
  }

  async getToken() {
    if (Capacitor.getPlatform() === "web") {
      //for cookies
    } else {
      return await localStorage.getItem(TOKEN);
    }
    return await localStorage.getItem(TOKEN);
  }

  async getTokenId() {
    if (Capacitor.getPlatform() === "web") {
      //for cookies
    } else {
      return await localStorage.getItem(TOKEN_ID);
    }
    return await localStorage.getItem(TOKEN_ID);
  }

  async getRefreshToken() {
    return await localStorage.getItem(REFRESH_TOKEN);
  }

  setToken(value: string, tokenId: string, dispatch: any) {
    if (Capacitor.getPlatform() === "web") {
      //for cookies
    } else {
      localStorage.setItem(TOKEN, value);
    }
    localStorage.setItem(TOKEN, value);
    localStorage.setItem(TOKEN_ID, `${tokenId}`);
    dispatch(setUserToken(value));
  }
  setLogin(data: any) {
    localStorage.setItem(LOGIN, JSON.stringify(data));
  }

  // async relogin() {
  //   let value = false;
  //   const user: string | null = localStorage.getItem(LOGIN);
  //   console.log("USER", user);
  //   if (!isEmpty(user)) {
  //     const data = JSON.parse(user ?? "");
  //     await userService
  //       .login(data)
  //       .then(async (response) => {
  //         const result = response.data;
  //         const id = result?.data?.roles?.id;
  //         const token = result?.token;
  //         if (id && token) {
  //           this.setToken(token);
  //           value = true;
  //         }
  //       })
  //       .catch((reason) => {
  //         const error = userService.getError(reason);
  //         console.log("ERROR", error, reason);
  //       });
  //   }
  //   return value;
  // }
}

export default new AuthApi();
