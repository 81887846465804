import React, { Fragment, useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import { deepPurple } from "@material-ui/core/colors";

import Avatar from "@material-ui/core/Avatar";

import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";

import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import Typography from "@material-ui/core/Typography";

import SelectField from "../SelectField/SelectField";
import logo from "../../assets/app_logo/africatik_logo.svg";

import { CardDataContext, LoggedContext, OnlineContext } from "../../context";
import { AllCardDatas } from "../../datas";
import {
  getCountryCode,
  getDisciplinesCode,
  getLevelCode,
  getPlatform,
} from "../Utils/Utils";

import { countryFlag, app_logo } from "../../assets";
import SearchComponent from "../SearchComponent/SearchComponent";
import axios from "axios";

import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";
import Grow from "@material-ui/core/Grow";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, toUpper } from "lodash";
import { MenuDrawer } from "./drawer";
import { RootState } from "../../redux/store";
import { ChoiceForm } from "./choiceForm";
import { setLevel } from "../../redux/levelReducer";
import { setProduct } from "../../redux/productReducer";
import { setCountry } from "../../redux/countryReducer";
import userHelper from "../../utils/helpers/userHelper";
import userService from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { StatusBar, Style } from "@capacitor/status-bar";
import { storageKeys } from "../../utils/helpers/tools";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    alignSelf: "center",
    height: "100%",
    width: "100%",
  },
  logoImg: {
    // width: '70%',
    "@media screen and (orientation:landscape)": {
      width: "20%",
      maxHeight: 50,
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  menuIcon: {
    justifyContent: "flex-end",
    alignItems: "center",
  },
  inputSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    backgroundColor: "#f8f8f8",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: "width 0.2s",
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("md")]: {},
  },
  drawer: {},
  offset: theme.mixins.toolbar,
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

// const countries = [
//   { name: "CAMEROUN", key: "cameroun", flag: countryFlag.cameroun },
//   { name: "CONGO", key: "congo", flag: countryFlag.congo },
//   {
//     name: "COTE D'IVOIRE",
//     key: "cote_divoire",
//     flag: countryFlag.cote_divoire,
//   },
//   { name: "DJIBOUTI", key: "djibouti", flag: countryFlag.djibouti },
//   { name: "ETHIOPIE", key: "ethiopie", flag: countryFlag.ethiopie },
//   { name: "GABON", key: "gabon", flag: countryFlag.gabon },
//   { name: "GUINEE", key: "guinee", flag: countryFlag.guinee },
//   { name: "KENYA", key: "kenya", flag: countryFlag.kenya },
//   { name: "MADAGASCAR", key: "madagascar", flag: countryFlag.mada },
//   { name: "MALI", key: "mali", flag: countryFlag.mali },
//   { name: "RCA", key: "rca", flag: countryFlag.rca },
//   { name: "RD CONGO", key: "rdc", flag: countryFlag.rdc },
//   { name: "SENEGAL", key: "senegal", flag: countryFlag.senegal },
//   { name: "SOMALIE", key: "somalie", flag: countryFlag.somalie },
//   { name: "TOGO", key: "togo", flag: countryFlag.togo },
//   { name: "HAITI", key: "haiti", flag: countryFlag.haiti },
//   { name: "MAURITANIE", key: "mauritanie", flag: countryFlag.mauritanie },
//   { name: "TUNISIE", key: "mauritanie", flag: countryFlag.tunisie },
//   //supplement Emmanuel Bulakitadi
//   { name: "ALGERIE", key: "algerie", flag: countryFlag.algerie },
//   { name: "BOTSWANA", key: "botswana", flag: countryFlag.botswana },
//   { name: "LIBERIA", key: "liberia", flag: countryFlag.liberia },
//   { name: "EUROPE", key: "europe", flag: countryFlag.europe },
//   {
//     name: "SIERRA LEONE",
//     key: "sierra_leonne",
//     flag: countryFlag.sierra_leone,
//   },
//   { name: "MAROC", key: "maroc", flag: countryFlag.maroc },
//   { name: "EGYPTE", key: "egypte", flag: countryFlag.egypte },
// ].sort(function (a, b) {
//   return a.name.localeCompare(b.name);
// });

const levels = [
  { name: "Aucun filtre" },
  { name: "Maternelle" },
  { name: "1ère primaire - CP" },
  { name: "2ème primaire - CE1" },
  { name: "3ème primaire - CE2" },
  { name: "4ème primaire - CM1" },
  { name: "5ème primaire - CM2" },
  { name: "6ème primaire - 6ème" },
  { name: "Secondaire" },
  { name: "Adultes" },
];

// const disciplines = [
//   { name: "Aucun filtre" },
//   { name: "Éveil-Sciences" },
//   { name: "Lettres" },
//   { name: "Logique" },
//   { name: "Mathématiques" },
//   { name: "Thèmes citoyens" },
//   //Supplement
//   { name: "Alphabétisation" },
// ];

//Header constitued of the AFricatik Logo and a Search Area
function Header(props: { load: boolean }) {
  const [drawer, setDrawer] = useState(false);
  const [previousCountry, setPreviousCountry] = useState<any>("");
  const user = useSelector((state: RootState) => state.user.profil);
  const products = useSelector((state: RootState) => state.product.all);
  const levels = useSelector((state: RootState) => state.level.all);
  const countries = useSelector((state: RootState) => state.country.all);
  const dispatch = useDispatch();

  const [showConnexionPaper, setShowConnexionPaper] = useState(false);

  const defaultCountry = window.localStorage.getItem("country");
  const country = useSelector((state: RootState) => state.country.current);
  const discipline = useSelector((state: RootState) => state.product.current);
  const level = useSelector((state: RootState) => state.level.current);
  const navigation = useNavigate();
  const hideStatusBar = async () => {
    if (getPlatform() === "ios") {
      await StatusBar.hide();
    }
  };

  const [circular, setCircular] = useState(true);

  const [padding, setPadding] = React.useState(50);

  const classes = useStyles();

  const toggleMenu = () => {
    setDrawer(!drawer);
  };
  const onlineContext = useContext(OnlineContext);

  const handleCountryChange = (event: any) => {
    const v = event.target.value;
    dispatch(setCountry(v));
    localStorage.removeItem(storageKeys.country);
    if (!isEmpty(v)) {
      localStorage.setItem(storageKeys.country, JSON.stringify(v));
    }
  };

  const handleLevelsChange = (event: any) => {
    const v = event.target.value;
    dispatch(setLevel(v));
    localStorage.removeItem(storageKeys.level);
    if (!isEmpty(v)) {
      localStorage.setItem(storageKeys.level, JSON.stringify(v));
    }
  };

  const handleDisciplineChange = (event: any) => {
    const v = event.target.value;
    dispatch(setProduct(v));
    localStorage.removeItem(storageKeys.discipline);
    if (!isEmpty(v)) {
      localStorage.setItem(storageKeys.discipline, JSON.stringify(v));
    }
  };
  const isLandscape = () =>
    window.matchMedia("(orientation:landscape)").matches;

  React.useEffect(() => {
    if (isLandscape()) {
      setPadding(0);
    } else {
      setPadding(50);
    }
    hideStatusBar();
  }, []);

  async function handleLogout() {
    setCircular(false);
    await userService
      .logout({})
      .then(async () => {})

      .catch(() => {
        setCircular(true);
      });
    await userHelper.setLogout(dispatch);
    if (getPlatform() === "electron") {
      window.location.href = "/home";
    } else {
      navigation("/home", { replace: true });
    }
    // navigation('/home',{replace:true})
  }

  window.addEventListener("orientationchange", function (event: any) {
    const or = event.target.screen.orientation.angle;
    if (or == 0) {
      setPadding(50);
    } else {
      setPadding(0);
    }
  });

  return (
    <Fragment>
      <Grow in={!onlineContext.online}>
        <Paper
          style={{
            width: 270,
            height: 70,
            position: "fixed",
            left: 10,
            bottom: 0,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <PortableWifiOffIcon fontSize="large" color="primary" />
          <Typography color="primary">Aucune connexion internet</Typography>
        </Paper>
      </Grow>

      <AppBar
        position="sticky"
        style={{
          background: "#f8f8f8",
          paddingInline: "40px",
          paddingBlock: "5px",
          //padding: getPlatform() === "ios" ? "25px" : "30px",
          paddingTop: getPlatform() === "ios" ? padding : undefined,
        }}
      >
        <Grid container className={classes?.root}>
          {/* Africatik Logo (left) and the Menu Icon (right) (Mobile design) */}
          <Grid
            item
            className={classes?.item}
            style={{ backgroundColor: "" }}
            xs={12}
            sm={12}
            md={1}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Africatik LOGO */}
              <img
                className={classes?.logoImg}
                //src={app_logo.africatik_logo}
                src={require("../../assets/logo-africatik-w-text.png")}
                alt="africatik-logo"
                style={{ maxWidth: "100px", width: "100%" }}
              />
              {props.load && (
                <div>
                  <CircularProgress size={20} />
                </div>
              )}
            </div>

            {/* Menu Icon */}
            {/**@ts-ignore */}
            <Hidden mdUp>
              <Grid
                item
                className={classes?.item}
                style={{ backgroundColor: "" }}
                xs={12}
                sm={6}
                md={6}
              >
                <SearchComponent />
              </Grid>

              <Grid item xs={3} sm={3} md={3} style={{ backgroundColor: "" }}>
                <Grid container className={classes?.menuIcon}>
                  <Grid item>
                    <IconButton onClick={() => setDrawer(true)}>
                      <MenuIcon fontSize="large" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Hidden>
          </Grid>
          {/* Search Section */}
          {/**@ts-ignore */}
          <Hidden smDown>
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                backgroundColor: "",
              }}
              xs={12}
              sm={12}
              md={10}
            >
              <SearchComponent />

              <Grow in={true}>
                <div>
                  <ChoiceForm
                    label="Pays"
                    item={country}
                    items={countries}
                    onChange={handleCountryChange}
                  />
                  <ChoiceForm
                    label="Niveaux"
                    item={level}
                    items={levels}
                    onChange={handleLevelsChange}
                  />
                  <ChoiceForm
                    label="Disciplines"
                    item={discipline}
                    items={products}
                    onChange={handleDisciplineChange}
                  />
                </div>
              </Grow>
            </Grid>
          </Hidden>
          {/**@ts-ignore */}
          <Hidden smDown>
            <Grid
              item
              md={1}
              style={{
                backgroundColor: "",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                padding: 10,
              }}
            >
              <Avatar
                onClick={() => {
                  setShowConnexionPaper(!showConnexionPaper);
                }}
                className={classes?.purple}
                style={{
                  cursor: "pointer",
                }}
                id="app-close"
              >
                {userHelper.getTitle(user?.email)}
              </Avatar>
            </Grid>
          </Hidden>
        </Grid>
      </AppBar>

      <MenuDrawer
        levels={levels}
        countries={countries}
        products={products}
        open={drawer}
        onClose={toggleMenu}
        variant={{}}
      />

      {/**@ts-ignore */}
      <Hidden smDown>
        <Grow in={showConnexionPaper}>
          <Paper
            style={{
              position: "fixed",
              zIndex: 9999,
              // top: 100,
              right: 0,
              width: 350,
              height: 350,
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                }}
              >
                <IconButton
                  onClick={() => setShowConnexionPaper(!showConnexionPaper)}
                >
                  <CloseIcon style={{ color: "red" }} />
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <Avatar
                  className={classes?.purple}
                  style={{ width: 80, height: 80 }}
                >
                  <h1>{userHelper.getTitle(user?.email)}</h1>
                </Avatar>
                <Typography style={{ marginTop: 20 }}>{user?.email}</Typography>
              </div>

              {<Divider />}

              <div
                style={{
                  display: isEmpty(user?.offer) ? "none" : "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Avatar
                  //className={classes?.purple}
                  className="img--logo"
                  style={{ display: "inline-block", objectFit: "contain" }}
                  src={!isEmpty(user?.offer?.logo) ? user?.offer?.logo : logo}
                />
                <Typography style={{ marginTop: 0 }}>
                  {user?.offer?.name}
                </Typography>
              </div>

              {circular ? (
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  style={{ marginTop: 50 }}
                  onClick={handleLogout}
                >
                  Se déconnecter
                </Button>
              ) : (
                <CircularProgress style={{ color: "#E87C02", marginTop: 50 }} />
              )}
            </div>
          </Paper>
        </Grow>
      </Hidden>

      {/* ToolBar Margin */}
      {/* <div className={classes?.offset} />
            <div className={classes?.offset} />  */}
    </Fragment>
  );
}

export default Header;
