import { Grow, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";

const CheckConnexion = (props) => {
  return (
    <>
      {props.children}
      <Grow in={true}>
        <Paper
          style={{
            width: 270,
            height: 70,
            position: "fixed",
            left: 10,
            bottom: 0,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            zIndex: 99999999,
          }}
        >
          <PortableWifiOffIcon fontSize="large" color="primary" />
          <Typography color="primary">
            Aucune connexion à Internet ou elle n'est pas stable.
          </Typography>
        </Paper>
      </Grow>
    </>
  );
};

export default CheckConnexion;
