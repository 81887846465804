import React from "react";

const Banner = (props) => {
  return (
    <div
      style={{
        backgroundImage: `url(${props.img})`,
        backgroundRepeat: "no-repeat",
        // border: "2px solid, red",
        // backgroundPositionY: "100%",
        width: "100%",
        height: "30%",
        backgroundPositionY: "74%",
        backgroundSize: "cover",
      }}
    ></div>
  );
};

export default Banner;
