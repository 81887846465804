// connexionSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

interface ConnexionData {
  status: boolean;
}

const initialState: ConnexionData = {
  status: true,
};

export const changeStatusAsync = createAsyncThunk(
  "connexion/changeStatusAsync",
  async (status: boolean) => {
    return status;
  },
);

export const connexionSlice = createSlice({
  name: "connexion",
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<boolean>) => {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeStatusAsync.fulfilled, (state, action) => {
      state.status = action.payload;
    });
  },
});

export const { setStatus } = connexionSlice.actions;

export default connexionSlice.reducer;
