import React, { useState, useContext } from "react";

import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grow from "@material-ui/core/Grow";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";

import Icon from "./icon.png";

import { LoggedContext, OnlineContext } from "../../context";
import userService from "../../services/userService";
import authService from "../../services/authService";
import { isEmpty, replace, toLower } from "lodash";
import { SessionList } from "../../card/splash";
import userHelper from "../../utils/helpers/userHelper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
//import { useMatomo } from "@datapunt/matomo-tracker-react";
import { getPlatform } from "../Utils/Utils";
import Banner from "../../banner/Banner";
// import electron from 'electron';
//import { Plugins } from '@capacitor/core';

function Login() {
  const [open, setOpen] = React.useState(false);
  const [sessions, setSessions] = React.useState([]);
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState("");
  const [emailHelper, setEmailHelper] = useState("");
  const navigation = useNavigate();
  //const { trackPageView, trackEvent } = useMatomo();

  const [passwordError, setPasswordError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordHelper, setPasswordHelper] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [passwordBlurError, setPasswordBlurError] = useState(false);
  const [emailBlurError, setEmailBlurError] = useState(false);

  const [circular, setCircular] = useState(true);

  const loggedContext = useContext(LoggedContext);
  const onlineContext = useContext(OnlineContext);

  const handleSubmit = (event) => {};

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const dispatch = useDispatch();

  async function onLogin() {
    setCircular(false);
    let message = ``;
    setErrorText("");
    await userService
      .login({ email, password })
      .then(async (response) => {
        const data = response.data;
        const token = data?.token;
        const token_id = data?.token_id;
        await authService.setToken(token, token_id, dispatch);
        await userHelper.setCurrentUser(data, dispatch);
        if (getPlatform() === "electron") {
          window.location.href = "/";
        } else {
          navigation("/", { replace: true });
        }
      })
      .catch((reason) => {
        let response = reason?.response ?? reason;
        if (
          response?.code === "ERR_NETWORK" ||
          response?.data?.code === "ERR_NETWORK" ||
          reason?.code === "ERR_NETWORK"
        ) {
          setErrorText(`Veuillez vérifier votre connexion, svp!`);
          setCircular(true);
          return;
        } else if (!isEmpty(response?.data)) {
          response = response.data;
          message =
            response?.message || `Veuillez vérifier votre connexion, svp!`;
          if (!isEmpty(response?.data)) {
            response = response.data;
            message =
              response?.error || `Veuillez vérifier votre connexion, svp!`;
          } else {
            message = `Une erreur est survenue, veuillez réessayer.`;
          }
        } else {
          setErrorText(`Veuillez vérifier votre connexion, svp!`);
          setCircular(true);
          return;
        }
        const data = response?.data ?? [];
        setErrorText(message);
        setError(true);
        setCircular(true);
        if (data.length > 0) {
          setOpen(true);
          setSessions(data);
        }
        setCircular(true);
      });
  }

  async function handleClick(url) {
    const plat = getPlatform();
  }

  async function init() {
    const tokenId = await authService.getTokenId();
    const user = await userHelper.getUserProfil();
    if (isEmpty(user) || isEmpty(tokenId)) {
      //setLogged(false);
    } else {
      if (toLower(getPlatform()) === "electron") {
        window.location.href = "/";
      } else {
        navigation("/", { replace: true });
      }
    }
    /* eslint-disable */
    //_paq.push(['trackEvent', 'Menu', 'login']);
    /*trackPageView({
      documentTitle: "Login page",
      href: "/home",
      //customDimensions:[{id:1,value:"Login"}]
      customDimensions: [{ id: 5, value: getPlatform() }]

    })*/
    ReactGA.send({ hitType: "pageview", page: "/home", title: "Login page" });
  }

  /**
   * This function checks rather email is valid
   */

  const checkEmailValidity = (email) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };

  /**
   * This function checks rather entered password is valid
   */

  const checkPasswordValidity = (password) => {
    const regex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return regex.test(password);
    /* if (password.length >= 8) {
      return true;
    }
    return false; */
  };

  React.useEffect(() => {
    init();
  }, []);

  function handleClickUrl(type, event) {
    // trackPageView({
    //   documentTitle:"Login page",
    //   href:event?.target?.href,
    //   //customDimensions:[{id:1,value:"Login"}]
    //   customDimensions:[{id:5,value:getPlatform()}]
    // })
    /*trackEvent({
      action: "urlClick",
      category: type,
      name: "Login Page",
      customDimensions: [{ id: 5, value: getPlatform() }],
      href: event?.target?.href,
      documentTitle: "Login page",
      value: type,
    });*/
    ReactGA.event({
      action: "urlClick",
      category: event?.target?.href,
      label: type,
    });
  }

  console.log("platform ", getPlatform());

  return (
    <div style={{ backgroundColor: "", height: "100%" }}>
      <Grow in={!onlineContext.online}>
        <Paper
          style={{
            width: 280,
            height: 60,
            position: "fixed",
            //left: 10,
            //top: 10,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <PortableWifiOffIcon fontSize="large" color="primary" />
          <Typography color="primary">Aucune connexion internet</Typography>
        </Paper>
      </Grow>
      <Banner img="/baniere.jpg" />
      <div
        style={{
          // backgroundColor: "red",
          padding: 0,
          height: "80%",
          width: "100%",
          position: "absolute",
          top: "20%",
          display: "flex",
          alignItems: "center",
          gap: 0,
          justifyContent: "center",
          flexDirection: "column",
          // border: "3px solid blue",
        }}
      >
        {/* <img src="/baniere.png" alt="" style={{ marginTop: 0 }} /> */}
        <p className="londrina-solid-regular" color="" style={{ fontSize: 30 }}>
          Bienvenue sur Africatik APP
        </p>
        <img
          style={{
            width: 40,
            height: 40,
            margin: "-10px",
            // border: "2px solid black",
          }}
          src={Icon}
          alt="africatik-logo"
        />

        <Paper
          style={{
            boxShadow: "",
            margin: 20,
            padding: 10,
            maxWidth: "400px",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-around",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Typography color="primary">CONNEXION</Typography> */}

          <form
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "330px",
            }}
            onSubmit={handleSubmit}
          >
            <Box sx={{ width: "100%" }}>
              <TextField
                error={emailError}
                autoFocus
                color="primary"
                id="outlined-search"
                label="Email"
                type="search"
                variant="outlined"
                style={{ marginTop: 0, width: "100%" }}
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                  if (e.target.value !== "") {
                    setError(false);
                    setEmailError(false);
                    setEmailHelper("");
                  }
                }}
                onBlur={() => setEmailBlurError(!checkEmailValidity(email))}
                helperText={emailHelper}
              />
              {emailBlurError && !checkEmailValidity(email) && (
                <span
                  style={{
                    color: "#F35B5B",
                    fontSize: 12,
                    textAlign: "left",
                  }}
                >
                  {email.length > 0 && (
                    <span>{"Email invalide (ex: joe@gmail.com)"}</span>
                  )}
                </span>
              )}
            </Box>

            <Box sx={{ width: "100%", margin: "10px 0" }}>
              <TextField
                error={passwordError}
                id="outlined-password-input"
                label="Mot de passe"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                variant="outlined"
                style={{ marginTop: 10, width: "100%" }}
                helperText={passwordHelper}
                onChange={(e) => {
                  const enteredValue = e.target.value;
                  setPassword(enteredValue);

                  if (enteredValue !== "") {
                    setError(false);
                    setPasswordError(false);
                    setPasswordHelper("");
                  }
                }}
                onBlur={() => {
                  setPasswordBlurError(!checkPasswordValidity(password));
                }}
                //show/hide password
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {passwordBlurError && !checkPasswordValidity(password) && (
                <span
                  style={{
                    color: "#F35B5B",
                    fontSize: 12,
                  }}
                >
                  {password.length > 0 && (
                    <span>
                      Le mot de passe doit avoir au moins 8 caractères, et
                      contenir au moins une majuscule, minuscule et un caractère
                      spécial
                      <br /> {`(Ex: 23Afric@tic)`}
                    </span>
                  )}
                </span>
              )}
            </Box>

            {error ? (
              <Box
                textAlign="left"
                m={2}
                // fontWeight="Bold"
                fontSize={12}
                color="#F35B5B"
              >
                {errorText}
              </Box>
            ) : null}

            {circular ? (
              <Button
                disabled={
                  !checkEmailValidity(email) || !checkPasswordValidity(password)
                }
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                style={{ margin: 10, color: "#fff" }}
                onClick={onLogin}
              >
                Se connecter
              </Button>
            ) : (
              <CircularProgress style={{ color: "#E87C02", margin: 10 }} />
            )}
          </form>

          {(getPlatform() === "web" || getPlatform() === "electron") && (
            <Typography align="center" style={{ marginTop: 5 }}>
              <Link
                href={`https://africatik.com/abonnement`}
                target="blank"
                rel="noopener"
                className="new_windows"
                id="__subscription"
                onClick={(event) => handleClickUrl("subscription", event)}
              >
                {`Vous n'avez pas de compte? Abonnez-vous`}
              </Link>
            </Typography>
          )}

          <Typography align="center" style={{ marginTop: 10 }}>
            <Link
              href={`${process.env.REACT_APP_ENV == "rec" ? process.env.REACT_APP_URL_REC : process.env.REACT_APP_URL_PROD}mot-de-passe`}
              target="blank"
              rel="noopener"
              id="__forgot"
              className="new_windows"
              onClick={(event) => handleClickUrl("password", event)}
            >
              {`Mot de passe oublié`}
            </Link>
          </Typography>
        </Paper>
      </div>
      <SessionList
        email={email}
        items={sessions}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}

export default Login;
