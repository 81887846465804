import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SelectInput from "@mui/material/Select/SelectInput";
import React, { useState } from "react";

const Subscribe = () => {
  const [offre, setOffre] = useState("");

  const offres = [
    { title: "Découverte", value: "1" },
    { title: "Essentielle", value: "2" },
    { title: "Intégrale", value: "3" },
  ];
  const zones = [
    { title: "RD CONGO", value: "RDC" },
    { title: "SENEGAL", value: "SEN" },
    { title: "MALI", value: "MLI" },
  ];

  const handleOffreChange = (value) => {
    setOffre(value);
  };
  return (
    <div
      style={{
        height: "100%",
        maxWidth: "350px",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 20,
      }}
    >
      <Typography>SOUSCRIPTION</Typography>
      <Stack direction={"column"} spacing={2}>
        <TextField
          label="Prenom et nom"
          type="text"
          placeholder="Saisir vos noms"
        />
        <Stack direction={"row"} spacing={2}>
          <TextField
            label="Adresse email"
            type="email"
            placeholder="Saisir votre adresse mail"
          />
          <TextField
            label="Téléphones"
            type="phone"
            placeholder="Saisir votre numéro de téléphone"
          />
        </Stack>

        <TextField
          label="Offre"
          // placeholder="Choisir une offre"
          select
          defaultValue={1}
          onChange={(e) => handleOffreChange(e.target.value)}
          // helperText="Choisir une offre"
        >
          {offres.map((item, key) => {
            return (
              <MenuItem key={key} value={item.value}>
                {item.title}
              </MenuItem>
            );
          })}
        </TextField>
        <TextField
          label="Adresse"
          type="text"
          placeholder="Saisir votre adresse"
        />
        <Stack direction={"row"} spacing={2}>
          <TextField
            label="Ville"
            type="text"
            placeholder="Saisir votre ville"
          />
          <TextField label="Pays" type="text" placeholder="Saisir votre pays" />
        </Stack>
        <TextField
          label="Code postal"
          type="text"
          placeholder="Saisir votre code postal"
        />

        <TextField
          label="Zone linguistique"
          // placeholder="Choisir une offre"
          select
          defaultValue={"RDC"}
          onChange={(e) => handleOffreChange(e.target.value)}
          // helperText="Choisir une offre"
        >
          <Divider />
          {zones.map((item, key) => {
            return (
              <MenuItem key={key} value={item.value}>
                {item.title}
              </MenuItem>
            );
          })}
        </TextField>
        <Stack direction={"row"} spacing={2}>
          <Button variant="contained" size="large">
            Souscrire
          </Button>
          <Button variant="contained" color="warning" size="large">
            Retour
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

export default Subscribe;
