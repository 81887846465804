import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./adminReducer";
import userReducer from "./userReducer";
import gameReducer from "./gameReducer";
import levelReducer from "./levelReducer";
import productReducer from "./productReducer";
import countryReducer from "./countryReducer";
import connextionReducer from "./connexionReducer";
const store = configureStore({
  reducer: {
    user: userReducer,
    admin: adminReducer,
    game: gameReducer,
    level: levelReducer,
    product: productReducer,
    country: countryReducer,
    connexion: connextionReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
