import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Avatar, Typography, makeStyles } from "@material-ui/core";
import { deepPurple } from "@mui/material/colors";
import {
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { ChoiceForm } from "./choiceForm";
import { CardDataContext, LoggedContext } from "../../context";
import { isEmpty, toUpper } from "lodash";
import { setCountry } from "../../redux/countryReducer";
import { setLevel } from "../../redux/levelReducer";
import { setProduct } from "../../redux/productReducer";
import userService from "../../services/userService";
import userHelper from "../../utils/helpers/userHelper";
import { useNavigate } from "react-router-dom";
import { storageKeys } from "../../utils/helpers/tools";
import logo from "../../assets/app_logo/africatik_logo.svg";
import { getPlatform } from "../Utils/Utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    alignSelf: "center",
    height: "100%",
    width: "100%",
  },
  logoImg: {
    // width: '70%',
    "@media screen and (orientation:landscape)": {
      width: "20%",
      maxHeight: 50,
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  menuIcon: {
    justifyContent: "flex-end",
    alignItems: "center",
  },
  inputSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    backgroundColor: "#f8f8f8",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: "width 0.2s",
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("md")]: {},
  },
  drawer: {},
  offset: theme.mixins.toolbar,
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

export const MenuDrawer: React.FC<{
  variant: any;
  open: boolean;
  onClose?: any;
  products: any[];
  levels: any[];
  countries: any[];
}> = ({ variant, open, onClose, countries, levels, products }) => {
  const classes = useStyles();
  const user = useSelector((state: RootState) => state.user.profil);

  const defaultCountry = window.localStorage.getItem("country");
  const country = useSelector((state: RootState) => state.country.current);
  const discipline = useSelector((state: RootState) => state.product.current);
  const level = useSelector((state: RootState) => state.level.current);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [circular, setCircular] = React.useState(true);

  const handleCountryChange = (event: any) => {
    const v = event.target.value;
    dispatch(setCountry(v));
    localStorage.removeItem(storageKeys.country);
    if (!isEmpty(v)) {
      localStorage.setItem(storageKeys.country, JSON.stringify(v));
    }
  };

  const handleLevelsChange = (event: any) => {
    const v = event.target.value;
    dispatch(setLevel(v));
    localStorage.removeItem(storageKeys.level);
    if (!isEmpty(v)) {
      localStorage.setItem(storageKeys.level, JSON.stringify(v));
    }
  };

  const handleDisciplineChange = (event: any) => {
    const v = event.target.value;
    dispatch(setProduct(v));
    localStorage.removeItem(storageKeys.discipline);
    if (!isEmpty(v)) {
      localStorage.setItem(storageKeys.discipline, JSON.stringify(v));
    }
  };

  async function onLogout() {
    setCircular(false);
    await userService
      .logout({})
      .then(async () => {})

      .catch(() => {
        setCircular(true);
      });
    await userHelper.setLogout(dispatch);
    if(getPlatform() === "electron"){
      window.location.href = "/home";
    }else{
      navigation("/home", { replace: true });
    }
  }

  return (
    <Drawer
      className={classes?.drawer}
      variant={variant}
      open={open}
      onClose={onClose}
      anchor="right"
    >
      <Grid container style={{ height: "100%", flexDirection: "column" }}>
        <Paper style={{ boxShadow: "none" }}>
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Paper>

        <Divider />

        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          {/* Countries choiceForm */}
          <ChoiceForm
            label="Pays"
            item={country}
            items={countries}
            onChange={handleCountryChange}
          />
          <ChoiceForm
            label="Niveaux"
            item={level}
            items={levels}
            onChange={handleLevelsChange}
          />
          <ChoiceForm
            label="Disciplines"
            item={discipline}
            items={products}
            onChange={handleDisciplineChange}
          />

          <div
            style={{
              display: isEmpty(user?.offer)?"none":"flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Avatar
              //className={classes?.purple}
              className="img--logo"
              style={{ display: "inline-block", objectFit: "contain" }}
              src={!isEmpty(user?.offer?.logo) ? user?.offer?.logo : logo}
            />
            <Typography style={{ marginTop: 0 }}>
              {user?.offer?.name}
            </Typography>
          </div>
        </div>

        {circular ? (
          <Button
            variant="text"
            color="primary"
            size="medium"
            style={{ marginTop: 20 }}
            onClick={onLogout}
          >
            Se déconnecter
          </Button>
        ) : (
          <CircularProgress
            style={{
              color: "#E87C02",
              marginTop: 20,
              marginBottom: 10,
              alignSelf: "center",
            }}
          />
        )}
      </Grid>
    </Drawer>
  );
};
