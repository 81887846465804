import axios from "axios";
import config from "./config";

export default axios.create({
  baseURL:
    process.env.REACT_APP_ENV == "rec"
      ? process.env.REACT_APP_URL_API_REC
      : process.env.REACT_APP_URL_API_PROD,
  headers: {
    "Content-Type": "application/json",
  },
});

export const axiosWeb = axios.create({
  baseURL:
    process.env.REACT_APP_ENV == "rec"
      ? process.env.REACT_APP_URL_REC
      : process.env.REACT_APP_URL_PROD,
  headers: {
    "Content-Type": "application/json",
  },
});
