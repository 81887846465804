import SelectField from "../SelectField/SelectField";

export function ChoiceForm(props: any) {
  return (
    <SelectField
      label={props.label}
      item={props.item ?? {}}
      items={props.items}
      onChange={props.onChange}
    />
  );
}
