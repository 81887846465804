import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getCountryCode } from "./../Utils/Utils";

import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";

import SearchIcon from "@material-ui/icons/Search";
import CancelIcon from "@material-ui/icons/Cancel";

import { AllCardDatas } from "./../../datas";
import { CardDataContext } from "./../../context";

import InputAdornment from "@material-ui/core/InputAdornment";
import { setGameSearch } from "../../redux/gameReducer";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  inputSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100%",
    margin: theme.spacing(1),
    transition: "width 0.2s",
  },
  input: {
    marginLeft: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("md")]: {},
  },
}));

function SearchComponent(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");

  const cardDatas = useContext(CardDataContext);

  const restoreContext = () => {
    const countryCode =
      getCountryCode(window.localStorage.getItem("country")) || "cd";

    cardDatas.setData(
      AllCardDatas.filter((data) => data.country === countryCode),
    );
  };

  React.useEffect(() => {
    setValue(window.localStorage.getItem("search") ?? "");
  }, []);

  return (
    <Paper className={classes.inputSection}>
      <InputBase
        value={value}
        className={classes.input}
        placeholder="Chercher une application"
        inputProps={{ "aria-label": "search google maps" }}
        style={{ paddingRight: 20, width: 300,paddingTop:10,paddingBottom:10 }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon style={{ color: "#757575", cursor: "pointer" }} />
          </InputAdornment>
        }
        endAdornment={
          value ? (
            <InputAdornment position="end">
              <CancelIcon
                fontSize="small"
                onClick={() => {
                  setValue("");
                  //restoreContext();
                  dispatch(setGameSearch(""));
          localStorage.setItem("search", "");
                }}
                style={{ color: "#757575", margin: 8, cursor: "pointer" }}
              />
            </InputAdornment>
          ) : null
        }
        onChange={(e) => {
          dispatch(setGameSearch(e.target.value));
          localStorage.setItem("search", e.target.value);
          setValue(e.target.value);
          // const countryCode =
          //   getCountryCode(window.localStorage.getItem("country")) || "cd";

          // const searchResult = AllCardDatas.filter(
          //   (card) => card.country === countryCode
          // ).filter((card) =>
          //   card.name.toLowerCase().includes(e.target.value.toLowerCase())
          // );

          // cardDatas.setData(searchResult);
        }}
      />
    </Paper>
  );
}

export default SearchComponent;
