import { isEmpty } from "lodash";

const keys = {
  country: "target-country",
  level: "target-level",
  discipline: "target-discipline",
  product: "target-product",
};

export function getParseJSonValue(elem: string) {
  let result = null;
  if (!isEmpty(elem)) {
    try {
      result = JSON.parse(elem);
    } catch (error) {}
  }
  return result;
}

export { keys as storageKeys };
