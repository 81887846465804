import React, { useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  select: {},
}));

function SelectField(props) {
  const classes = useStyles();
  const [flagImages, setFlagImages] = useState({});

  const [item, setItem] = useState(props.item);

  const items = props.items;

  const handleChange = (e) => {
    setItem(e.target.value);

    props.onChange(e);
  };

  React.useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  React.useEffect(() => {
    setItem(props.item);

    const imagePromises = {};
    items.forEach((country, index) => {
      const img = new Image();
      img.src = country.flag;
      imagePromises[country.flag] = new Promise((resolve) => {
        img.onload = () => {
          resolve({ [country.flag]: img });
        };
      });
    });

    // Attendez que toutes les promesses de chargement d'images soient résolues
    Object.keys(imagePromises).forEach(async (key, index) => {
      const loadedImages = await Promise.resolve(imagePromises[key]);
      const abc = { ...flagImages, [key]: loadedImages };
      setFlagImages(abc);
    });
  }, [props.items]);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">
        {props.label}
      </InputLabel>
      <Select
        labelId={`demo-simple-select-outlined-label-${props?.uid}`}
        id={`demo-simple-select-outlined-${props?.uid}`}
        value={item}
        onChange={handleChange}
        className={classes.select}
        label={props.label}
        style={{ maxHeight: 50 }}
        renderValue={(value) => {
          //const elem = props.items?.find(p=>p.id==value);
          const elem = value;
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {elem?.flag ? (
                <img
                  style={{ width: 40, height: 30, borderRadius: 5 }}
                  src={elem.flag}
                  alt=""
                />
              ) : null}

              <Typography variant="subtitle2">{elem.name}</Typography>
            </div>
          );
        }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {console.log(flagImages[item.flag]?.src)}
            {item.flag ? (
              <img
                style={{ width: 40, height: 30, borderRadius: 5 }}
                src={flagImages[item.flag]?.src || item.flag}
                alt=""
              />
            ) : null}
            <Typography variant="subtitle2" style={{ marginLeft: 10 }}>
              {item.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectField;
