import React from "react";
import { useState } from "react";
import { StatusBar, Style } from "@capacitor/status-bar";
import "./firebase";
import "./assets/styles/app.scss";

// import { Plugins } from "@capacitor/core";
import { LoggedContext, OnlineContext } from "./context";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import CheckConnexion from "./components/checkConnexion/CheckConnexion";
import { colorPrimary } from "./utils/colors";
import { SplashScreen } from "./card/splashScreen";
import { ConnectLayout } from "./layouts/connected";
import { PublicLayout } from "./layouts/public";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { getPlatform } from "./components/Utils/Utils";
import { Offline, Online } from "react-detect-offline";
import ChangeStatus from "./components/checkConnexion/ChangeStatus";

// Plugins.App.addListener("backButton", () => {
//   Plugins.App.exitApp();
// });

const theme = createTheme({
  palette: {
    primary: {
      main: colorPrimary,
    },

    // primary: {
    //   main: colorPrimary,
    // },
    // secondary: {
    //   main: colorPrimary,
    // },
  },
});

function App() {
  const [logged, setLogged] = useState(false);
  const [appOnline, setAppOnline] = useState(true);
  const [open, setOpen] = React.useState(false);
  // const route = useLocation();

  React.useEffect(() => {
    if (getPlatform() !== "electron") {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
    hideStatusBar();
  }, []);

  const hideStatusBar = async () => {
    const platform = getPlatform();
    if (platform === "ios" || platform === "android") {
      await StatusBar.hide();
    }
  };

  return (
    <BrowserRouter basename="/" >
      <div style={{ maxWidth: "100%", height: "100vh", overflowX: "hidden" }}>
        <ThemeProvider theme={theme}>
          <LoggedContext.Provider
            value={{ logged: logged, setLogged: setLogged }}
          >
            <OnlineContext.Provider
              value={{ online: appOnline, setOnline: setAppOnline }}
            >
              <Routes>
                <Route element={<ConnectLayout />} path="/*" />
                <Route element={<PublicLayout />} path="home/*" />
                {/* <Route path="*" element={<NotFoundScreen />} /> */}
              </Routes>

              <Offline
                polling={{
                  interval: 10000,
                  timeout: 10000,
                  enabled: false,
                  url: "https://httpbin.org/get",
                }}
              >
                <CheckConnexion />
                <ChangeStatus status={false} />
              </Offline>
              <Online>
                <ChangeStatus status={true} />
              </Online>
            </OnlineContext.Provider>
          </LoggedContext.Provider>
          <SplashScreen open={open} setOpen={setOpen} />
        </ThemeProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
