import React from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Slide from "@mui/material/Slide";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { isEmpty } from "lodash";
import userService from "../services/userService";
import dateHelper from "../utils/helpers/dateHelper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      <div></div>
    </Slide>
  );
});

const Splash = styled.div`
  height: 100vh;
  top: 0;
  width: 100%;
  z-index: 300;
  background: #fff;
  position: fixed;
  transition: background cubic-bezier(0.445, 0.05, 0.55, 0.95) 1.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SessionList: React.FC<{
  onStart?: any;
  open: boolean;
  email: string;
  setOpen: any;
  items: any[];
}> = ({ onStart, open = true, setOpen, items, email }) => {
  const [current, setCurrent] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      window.document.body.style.height = "100vh";
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.height = "auto";
      window.document.body.style.overflow = "auto";
    }
  }, [open]);

  function accessDate(d: any) {
    if (isEmpty(d)) {
      return `aucun accès`;
    } else {
      const time = new Date(d).getTime();
      const now = new Date().getTime();
      const diff = now - time;
      const label = dateHelper.getRemainTime(diff);
      return `dernière activité: ${label} environ`;
    }
  }

  function handleChoice(e: any, v: any) {
    setCurrent(v);
  }

  async function handleDisconnect() {
    setLoading(true);
    await userService
      .disconnect({ email, id: current })
      .then(() => {
        setOpen(false);
      })
      .catch(() => {});
    setLoading(false);
  }

  return (
    <React.Fragment>
      {open && (
        <Splash>
          {/* <div className='loading'>
              <Box>
                <CircularProgress size={90} />
              </Box>
            </div> */}
          {/* <p>{`Les sessions de connexion sont saturées`}</p> */}
          <p>{`Votre compte Africatik est utilisé sur un trop grand nombre d'appareils. Veuillez vous déconnecter.`}</p>
          <Box style={{ padding: "10px 0" }}>
            <Grid container spacing={3}>
              <Grid item flex={1}>
                <RadioGroup value={current} onChange={handleChoice}>
                  {items.map((p, i) => (
                    <Grid
                      container
                      key={`choice_card_${i}`}
                      flexWrap="nowrap"
                      alignItems="center"
                      gap={4}
                    >
                      <Grid
                        item
                        sx={{
                          background: "#f7f7f7",
                          borderRadius: 2,
                          flex: 1,
                          margin: "5px 0",
                          padding: "0 10px",
                          boxShadow: "1px 1px 3px #3339",
                          transition:
                            "all 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)",
                          "&:hover": {
                            boxShadow: "1px 1px 3px #111",
                            background: "#fff",
                          },
                        }}
                      >
                        <FormControlLabel
                          value={`${p.id}`}
                          control={<Radio />}
                          disabled={loading}
                          label={`UTILISATEUR ${i + 1}`}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            <div style={{ textAlign: "center" }}>
              <Button
                disabled={isEmpty(current) || loading}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                style={{ margin: 10, color: "#fff" }}
                onClick={handleDisconnect}
              >
                {`Désactiver cet utilisateur`}
                {loading && <CircularProgress size={22} />}
              </Button>
            </div>
          </Box>
        </Splash>
      )}
    </React.Fragment>
  );
};
