import React, { useState, useEffect } from "react";

import { getOrientation, getPlatform } from "./../Utils/Utils";
import PlaySection from "./PlaySection";
import { useLocation } from "react-router-dom";
//import { useMatomo } from "@datapunt/matomo-tracker-react";
import ReactGA from "react-ga4";
import { StatusBar, Style } from "@capacitor/status-bar";

//A play Component returning an hideable PlaySection Component
function Play(props) {
  const [hide, setHide] = useState(false);
  const [url, setUrl] = React.useState("");
  const route = useLocation();
  //const { trackEvent, trackPageView } = useMatomo()
  const bgColor = window.localStorage.getItem("bgColor");
  const cancelColor = window.localStorage.getItem("cancelColor");
  const appOrientation = window.localStorage.getItem("orientation");
  const [paddingRight, setPaddingRight] = React.useState(50);
  const [paddingTop, setPaddingTop] = React.useState(50);
  const vPadding = 60;
  const isLandscape = () =>
    window.matchMedia("(orientation:landscape)").matches;
  const hideStatusBar = async () => {
    if(getPlatform() === "ios"){
      await StatusBar.hide();
    }
    };


  var universalOrientation;

  if (appOrientation === "both") {
    universalOrientation = getOrientation();
  } else if (appOrientation === "land") {
    universalOrientation = "landscape";
  } else {
    universalOrientation = "landscape";
  }

  useEffect(()=>{
    getOrientation() === universalOrientation
        ? setHide(false)
        : setHide(true);
    hideStatusBar();
  },[])

  useEffect(() => {
    getOrientation() === universalOrientation ? setHide(false) : setHide(true);
    if (isLandscape()) {
      setPaddingTop(0);
      setPaddingRight(vPadding);
    } else {
      setPaddingTop(vPadding);
      setPaddingRight(0);
    }

    window.addEventListener("orientationchange", () => {
      getOrientation() === universalOrientation
        ? setHide(false)
        : setHide(true);
    });
    const src = window.localStorage.getItem("src");
    const name = window.localStorage.getItem("app-name");
    setUrl(src);
    // trackEvent({
    //   action:"use-app",category:"using",value:src,
    //   name:name,documentTitle:name,customDimensions:[{
    //     id:1,value:name,
    //   }]
    // });
    /*trackPageView({
      documentTitle: name,
      customDimensions: [{ id: 1, value: name }, { id: 5, value: getPlatform() }]
    });*/
    ReactGA.send({ hitType: "pageview", page: "/play", title: name });
  }, []);

  window.addEventListener(
    "orientationchange",
    function (event) {
      const or = event.target.screen.orientation.angle;
      if (or == 0) {
        setPaddingTop(vPadding);
        setPaddingRight(0);
      } else {
        setPaddingTop(0);
        setPaddingRight(vPadding);
      }
    }
  );

  return (
    <div
      style={{
        //paddingTop: getPlatform() === "ios" ? paddingTop : undefined,
        //paddingRight: getPlatform() === "ios" ? paddingRight : undefined,
      }}
    >
      <PlaySection
        hide={hide}
        src={url}
        cancelColor={cancelColor}
        bgColor={bgColor}
      />
    </div>
  );
}

export default Play;
