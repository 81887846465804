// ChangeStatusComponent.tsx
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeStatusAsync } from "../../redux/connexionReducer";

export default function ChangeStatus({ status }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeStatusAsync(status));
  }, [dispatch]);

  return <></>;
}
