import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Refresh } from "@material-ui/icons";
import ReactGA from "react-ga4";
import { RootState } from "../../redux/store";
import { Capacitor } from "@capacitor/core";

import AppCard from "../AppCard/AppCard";

import { CardDataContext } from "./../../context";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import userHelper from "../../utils/helpers/userHelper";
import { Modal, notification } from "antd";
import { toUpper } from "lodash";
//import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  item: {},
}));

//Will contain all app card components
function CardContainer(props) {
  const classes = useStyles();

  const cardDatas = useContext(CardDataContext);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  //const { trackPageView } = useMatomo()
  const search = useSelector((state) => state.game.search);
  const games = useSelector((state) => state.game.all);
  const byOffer = useSelector((state) => state.game.byOffer);

  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [component, setComponent] = React.useState("");
  const [modalTitle, setModalTitle] = React.useState();

  const discipline = useSelector((state) => state.product.current);
  const products = useSelector((state) => state.product.all);

  const onFilterOrderType = React.useCallback(() => {
    if (byOffer) {
      setData([...cardDatas.datas].sort((a, b) => a.disabled - b.disabled));
    } else {
      setData(cardDatas.datas);
    }
  }, [byOffer, cardDatas]);

  async function init() {
    setLoading(true);
    await userHelper.initData(dispatch);
    setLoading(false);
  }

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/", title: "App list" });
  }, []);

  React.useEffect(() => {
    onFilterOrderType();
  }, [byOffer, cardDatas]);

  function handleClickDisabledApp(item) {
    const offers = item.offers ?? [];
    let text = `<div>`;
    if (offers.length === 1) {
      text += `Pour profiter de ce contenu, nous vous invitons à vous abonner à l'offre <strong>${offers[0]?.name ?? ""}</strong>.`;
    } else if (offers.length > 1) {
      text += `Pour profiter de ce contenu, nous vous invitons à vous abonner à l'une des offres suivantes: `;
      text += "<ul>";
      offers?.forEach((p, i) => {
        text += `<li><strong>${p.name}</strong></li>`;
      });
      text += `</ul>`;
    } else {
      text += `Cette application n'est pas disponible dans votre formule. <a href="https://africatik.com/contact" target="_blank">Veuillez nous contacter</a>.`;
    }
    text += "</div>";
    setModalTitle(toUpper(item?.name));
    setComponent(text);
    setOpen(true);
  }

  return (
    <div id="apps__list">
      {cardDatas.datas.length === 0 && (
        <Box>
          <Grid
            container
            style={{
              height: "88.8vh",
              flexDirection: "column",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography style={{ fontSize: 22 }}>
              {search?.length === 0 &&
                games.length == 0 &&
                `Veuillez réactualiser en cliquant ici.`}
              {search?.length === 0 &&
                games.length !== 0 &&
                `Aucune application trouvée pour le filtre indiqué...`}
              {search?.length > 0 && (
                <>
                  {`Aucune application trouvée  pour le mot clé "`}
                  <Typography
                    sx={{ fontWeight: "bold", display: "inline", fontSize: 22 }}
                  >
                    {search}
                  </Typography>
                  {`".`}
                </>
              )}
            </Typography>
            {!loading && games.length == 0 && (
              <IconButton onClick={init}>
                <Refresh />
              </IconButton>
            )}
            {loading && <CircularProgress size={25} />}
          </Grid>
        </Box>
      )}
      {/* Affichage par discipline */}
      {discipline.id === 0 && (
        <>
          {products
            .filter((p) => p.id !== 0)
            .map((product) => {
              return (
                <>
                  <h2
                    className="londrina-solid-regular"
                    style={{ padding: "16px" }}
                  >
                    {product.name}
                  </h2>

                  <section id={`apps_list_${product.id}`}>
                    {data.length > 0 && (
                      <Grid
                        spacing={3}
                        alignItems="stretch"
                        className={`${classes.root} apps__data`}
                        container
                        justify="space-evenly"
                      >
                        {[...data]
                          .filter((data) =>
                            data.products.map((d) => d.id).includes(product.id),
                          )
                          .sort((a, b) => a.disabled - b.disabled)
                          .map((card, i) => {
                            return (
                              <Grid
                                className={classes.item}
                                key={`card_${i}`}
                                item
                                xs={12}
                                sm={4}
                                md={3}
                                lg={3}
                                xl={3}
                              >
                                <AppCard
                                  imageUrl={card.logo_url}
                                  name={card.name}
                                  category={card.products}
                                  app_repos={card?.prod_url || card.app_repos}
                                  bgColor={card.bg_color}
                                  cancelColor={card.cancel_color}
                                  orientation={card.orientation}
                                  disabled={card.disabled}
                                  item={card}
                                  onClickDisableApp={handleClickDisabledApp}
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                    )}
                  </section>
                </>
              );
            })}
        </>
      )}

      {/* Affichage des apps par discipline */}
      {discipline.id !== 0 && (
        <section id="apps_list">
          {data.length > 0 && (
            <Grid
              spacing={3}
              alignItems="stretch"
              className={`${classes.root} apps__data`}
              container
              justify="space-evenly"
            >
              {[...data]
                .sort((a, b) => a.disabled - b.disabled)
                .map((card, i) => {
                  return (
                    <Grid
                      className={classes.item}
                      key={`card_${i}`}
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      lg={3}
                      xl={3}
                    >
                      <AppCard
                        imageUrl={card.logo_url}
                        name={card.name}
                        category={card.products}
                        app_repos={card?.prod_url || card.app_repos}
                        bgColor={card.bg_color}
                        cancelColor={card.cancel_color}
                        orientation={card.orientation}
                        disabled={card.disabled}
                        item={card}
                        onClickDisableApp={handleClickDisabledApp}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </section>
      )}

      <Modal
        okText="J'ai compris"
        okType="link"
        cancelButtonProps={{ style: { display: "none" } }}
        forceRender={<div></div>}
        title={modalTitle}
        open={open}
        centered
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
      >
        <div>
          <div dangerouslySetInnerHTML={{ __html: component }} />
        </div>
      </Modal>
    </div>
  );
}

export default CardContainer;
