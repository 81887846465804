import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "../components/Login/Login";
import authService from "../services/authService";
import userHelper from "../utils/helpers/userHelper";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { getPlatform } from "../components/Utils/Utils";
import Subscribe from "../components/Subscribe/Subscribe";

export function PublicLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function reload() {
    if (getPlatform() === "electron") {
      window.location.href = "/";
    } else {
      navigate("/", { replace: true });
    }
  }

  const init = React.useCallback(async () => {
    const tokenId = await authService.getTokenId();
    const token = await authService.getToken();
    const user = await userHelper.getUserProfil();
    if (!isEmpty(user)) {
      if (isEmpty(token) && !isEmpty(user.token)) {
        authService.setToken(user.token, user.token_id, dispatch);
        authService.removeMotif();
        //navigate("/");
        reload();
      } else if (!isEmpty(token)) {
        authService.removeMotif();
        //navigate("/");
        reload();
      }
    } else {
      const motif = await authService.getMotif();
      if (!isEmpty(motif)) {
        notification.warning({
          message: "Déconnexion externe",
          description: motif,
          duration: 30,
        });
        authService.removeMotif();
      }
    }
  }, []);

  React.useEffect(() => {
    init();
  }, []);

  return (
    <Routes>
      <Route path={`/`} element={<Login />} key={`login_public`} />
      <Route path={`/subscribe`} element={<Subscribe />} key={`login_public`} />
    </Routes>
  );
}
