class DateHelper {
  private yearMilliseconds = 365 * 24 * 3600 * 1000;
  private monthMilliseconds = 30 * 24 * 3600 * 1000;
  private WeekMilliseconds = 7 * 24 * 3600 * 1000;
  private dayMilliseconds = 24 * 3600 * 1000;
  private hourMilliseconds = 1 * 3600 * 1000;
  private minuteMilliseconds = 60 * 1000;
  private secondMilliseconds = 1000;

  getRemainTime(time: number) {
    let result = ``;
    let type = ``;
    let count = 0;
    const tests: any[] = [
      { type: "années", n: this.yearMilliseconds },
      { type: "mois", n: this.monthMilliseconds },
      { type: "semaines", n: this.WeekMilliseconds },
      { type: "jours", n: this.dayMilliseconds },
      { type: "heures", n: this.hourMilliseconds },
      { type: "minutes", n: this.minuteMilliseconds },
      { type: "secondes", n: this.secondMilliseconds },
    ];
    tests.forEach((p) => {
      if (count === 0) {
        const n = time / p.n;
        if (n >= 1) {
          type = p.type;
          count = Math.ceil(n);
        }
      }
    });
    return `${count} ${type}`;
  }
}

export default new DateHelper();
