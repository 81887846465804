import React, { useState } from "react";

import Header from "../Header/Header";
import CardContainer from "../CardContainer/CardContainer";

import { CardDataContext } from "../../context";

import { AllCardDatas } from "../../datas";
import { getCountryCode } from "../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isEmpty, toLower } from "lodash";
import { setGames } from "../../redux/gameReducer";
import userHelper from "../../utils/helpers/userHelper";
import { getParseJSonValue, storageKeys } from "../../utils/helpers/tools";
import { getDatabase, onValue, ref, update } from "firebase/database";
import authService from "../../services/authService";
import { Grid } from "@mui/material";

const filterUniqueById = (items:Array<any>) => {
  const seenIds = new Set();
  return items.filter((item) => {
    if (seenIds.has(item.id)) {
      return false;
    } else {
      seenIds.add(item.id);
      return true;
    }
  });
};
/* The Home container is a component that contain the header and the cardContainer */
function HomeContainer(props: any) {
  const countryCode = window.localStorage.getItem("country")
    ? getCountryCode(window.localStorage.getItem("country"))
    : "cd";

  const cardDatas = AllCardDatas.filter((data) => data.country === countryCode);

  const [datas, setData] = useState<any[]>([]);

  const games = useSelector((state: RootState) => state.game.all);
  const gameSelecteds = useSelector((state: RootState) => state.game.list);
  const country = useSelector((state: RootState) => state.country.current);
  const countries = useSelector((state: RootState) => state.country.all);
  const product = useSelector((state: RootState) => state.product.current);
  const level = useSelector((state: RootState) => state.level.current);
  const search = useSelector((state: RootState) => state.game.search);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setData(gameSelecteds);
  }, [gameSelecteds]);

  React.useEffect(() => {
    onInit();
  }, [level, product, country, search]);

  const onInit = React.useCallback(() => {
    init();
  }, [level, product, country, search]);

  //FIltre par pays d'abonné
  const gameByContries = (jeux: any[], pays: any[]): any[] => {
    let finalGames: any[] = [];
    pays.map((item) => {
      jeux.map((item2) => {
        item2.countries.map((item3: any) => {
          if (item3.id === item.id) {
            finalGames.push(item2);
            return;
          }
        });
      });
    });
    return finalGames;
  };
  async function init() {
    window.localStorage.setItem("orientatinpm on", "land");
    let items: any[] = gameByContries(games, countries);
    // games.filter((game)=>return game.countries)
    // console.log("apps FILTRES--------->");
    // console.log(gameByContries(games, countries));
    // console.log("apps non FILTRES--------->");
    // console.log(games);
    const value = search;
    if (isEmpty(value)) {
      dispatch(setGames(games));
    } else {
      items = items.filter((p) => toLower(p.description).includes(toLower(value)));
    }
    if (country?.id > 0) {
   
      items = items.filter((p: any) => {
        const list: any[] = p.countries ?? [];
        const index = list.findIndex((q) => q.id === country.id);
        return index !== -1;
      });
    }
    if (level?.id > 0) {
      items = items.filter((p: any) => {
        const list: any[] = p.levels ?? [];
        const index = list.findIndex((q) => q.id === level.id);
        return index !== -1;
      });
    }
    if (product?.id > 0) {
      items = items.filter((p: any) => {
        const list: any[] = p.products ?? [];
        const index = list.findIndex((q) => q.id === product.id);
        return index !== -1;
      });
    }
    items = filterUniqueById(items);
    dispatch(setGames(items));
  }

  const onCallFirebase = React.useCallback(() => {
    onFirebase();
  }, []);

  React.useEffect(() => {
    onCallFirebase();
  }, []);

  async function onFirebase() {
    const user = await userHelper.getUserProfil();
    const db = getDatabase();
    const tokenId = await authService.getTokenId();
    //const notifRef = ref(db,`loading/${user?.id}`);
    const notifRef = ref(db, `loading/${tokenId}`);
    onValue(notifRef, (snapshot) => {
      if (snapshot.exists()) {
        const v: any = snapshot.toJSON();
        const key = snapshot.ref;
        if (v?.state) {
          v.state = false;
          userHelper.setCurrentUser(user, dispatch);
          userHelper.initData(dispatch);
          update(notifRef, v);
        }
      }
    });
  }

  return (
    <CardDataContext.Provider value={{ datas: datas, setData: setData }}>
      <Header load={loading} />
      <div
        className="banner-container londrina-solid-regular"
        style={{
          width: "100%",
          height: "30%",
          backgroundImage: "url('baniere.jpg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionY: "-50px",
          color: "white",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          fontSize: 25,
          // padding: 10,
          // border: "2px solid red",
        }}
      >
        <p
          className="banner-text"
          style={{
            // marginLeft: "20px",
            width: "55%",
            textAlign: "center",
            padding: 0,
            // border: "3px solid red",
          }}
        >
          {" "}
          Africatik facilite l'apprentissage avec des contenus interactifs qui
          mettent en scène le quotidien de vos enfants.
        </p>
      </div>
      {/* {typeof games[0].products} */}
      <CardContainer />
    </CardDataContext.Provider>
  );
}

export default HomeContainer;
