import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface adminData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: adminData = {
  current: {},
  all: [],
  list: [],
};

export const adminSlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setCountry: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setCountries: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllCountries: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllCountries, setCountries, setCountry } = adminSlice.actions;
export default adminSlice.reducer;
