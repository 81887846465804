import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import ReactGA from "react-ga4";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

import { Box, Typography, Modal } from "@mui/material";

import Fade from "@material-ui/core/Grow";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, notification } from "antd";
import { Capacitor } from "@capacitor/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      //margin: theme.spacing(2),
      margin: 0,
    },
    justifyContent: "center",
  },
  card: {
    //padding: theme.spacing(1),
    padding: 0,
  },
  cardArea: {
    display: "flex",
    flexDirection: "column",
    // border: "1px solid green"
  },
  cardMedia: {
    width: "70%",
    // border: "1px solid red"
  },
  cardContent: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  chip: {
    width: "60%",
    backgroundColor: "#e97b00",
    color: "white",
  },
}));

function AppCard(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const navigation = useNavigate();
  //const { trackEvent } = useMatomo();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { status } = useSelector((state) => state.connexion);

  function getOfferUrl(item) {
    const offers = item.offers ?? [];
    if (offers.length > 0) {
      return offers[0]?.subscription_link ?? "https://africatik.com/shop";
    } else {
      return "https://africatik.com/shop";
    }
  }

  const onClick = async (src, bgColor, cancelColor, orientation, name) => {
    // console.log("CardItem clicked ", bgColor,cancelColor);
    /*trackEvent({
      action: "click-app",
      category: "click",
      name: name ?? "app-url",
      value: src,
    });*/
    if (
      Capacitor.getPlatform() === "android" ||
      Capacitor.getPlatform() === "ios"
    ) {
      await window.screen.orientation.lock("landscape-primary");
    }
    ReactGA.event({
      category: "click",
      action: "click-app",
      label: name, // optional
      value: src, // optional, must be a number
      //nonInteraction: true, // optional, true/false
      //transport: "xhr", // optional, beacon/xhr/image
    });

    window.localStorage.removeItem("bgColor");
    window.localStorage.removeItem("cancelColor");

    window.localStorage.setItem("src", src);
    window.localStorage.setItem("app-name", name);
    window.localStorage.setItem("bgColor", bgColor);
    window.localStorage.setItem("cancelColor", cancelColor);
    window.localStorage.setItem("orientation", orientation || "land");
    navigation("play", { replace: true });
  };

  function handleClickDisabledApp(item) {
    /*const offers = item.offers ?? [];
    let text = ``;
    if(offers.length === 1){
      text = `Cette application n'est disponible que sur le bouquet ${offers[0]?.name ?? ""}. Veuillez changer de formule afin d'en bénéficier.`;
    }else if(offers.length > 1){
      text = `Cette application est disponible sur des bouquets ci-après: `;
      offers?.forEach((p,i)=>{
        if(i>0){
          text+=", ";
        }
        text += `${p.name}`;
      })
      text+=`. \nVeuillez changer de formule afin d'en bénéficier.`;
    }
    notification.warning({
      message:"Application non disponible",
      placement:"bottom",
      description:text,
      style:{style:{zIndex:9999999},}})*/
    if (typeof props.onClickDisableApp === "function") {
      props.onClickDisableApp(item);
    }
  }

  return (
    <Fade style={{ height: "100%" }} in={true}>
      <div className={classes.root} style={{ position: "relative" }}>
        <Card
          className={classes.card}
          style={{
            width: "100%",
            //minHeight: "170px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
          onClick={() => {
            if (!status) {
              handleOpen();
            } else {
              onClick(
                props.app_repos,
                props.bgColor,
                props.cancelColor,
                props.orientation,
                props.name,
              );
            }
          }}
        >
          <CardActionArea
            //href="/play"
            className={classes.cardArea}
          >
            <CardMedia
              className={`${classes.cardMedia} ${props.disabled ? "img--disable" : ""}`}
              component="img"
              alt={`${props.name}`}
              image={props.imageUrl}
              title={`${props.name}`}
            />
            {props.item.description && <Typography style={{padding: 6}} variant="h6" component="p">
              {props.item.description}
            </Typography>}
          </CardActionArea>


        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "1px solid #e60000",
              boxShadow: 24,
              p: 4,
              textAlign: "center",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <ReportProblemIcon style={{ color: "#e60000", fontSize: 60 }} />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Pour profiter pleinement de l'application{" "}
              <span style={{ color: "#e60000" }}>{props.name}</span>, une
              connexion internet est nécessaire.
            </Typography>
          </Box>
        </Modal>
        {
          <div
            //onClick={() => handleClickDisabledApp(props.item)}
            style={{
              width: "100%",
              background: "#3332",
              position: "absolute",
              top: 0,
              cursor: "pointer",
              height: "100%",
              display: props.disabled ? "block" : "none",
            }}
          >
            <div style={{ position: "absolute", top: 8, right: 8 }}>
              <Button
                rel="noopener"
                id={`app_${props.item?.id}`}
                className="app__button"
                href={getOfferUrl(props.item)}
                target="_blank"
                type="primary"
                shape="default"
              >
                {`S'abonner`}
              </Button>
            </div>
            <div
              style={{ width: 20, position: "absolute", bottom: 10, right: 10 }}
            >
              <img
                style={{ width: "100%", objectFit: "cover" }}
                src={require("../../assets/lock.png")}
              />
            </div>
          </div>
        }
      </div>
    </Fade>
  );
}

const Message = ({ item }) => {
  const offers = item.offers ?? [];
  let text = ``;
  if (offers.length === 1) {
    text = (
      <div>
        {`Pour profiter de ce contenu, nous vous invitons à vous abonner à l'offre `}
        <strong>{offers[0]?.name ?? ""}</strong>.
      </div>
    );
  } else if (offers.length > 1) {
    text = (
      <div>
        Pour profiter de ce contenu, nous vous invitons à vous abonner à l'une
        des offres suivantes:
      </div>
    );
  } else {
    text = (
      <div>
        Cette application n'est pas disponible dans votre formule.{" "}
        <a href="https://africatik.com/contact" target="_blank">
          Veuillez nous contacter
        </a>
        .
      </div>
    );
  }
  //return text;
  return (
    <div>
      {text}
      {offers.length > 0 && (
        <ul>
          {offers?.map((p, i) => {
            return (
              <li>
                <strong>{p.name}</strong>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default AppCard;
