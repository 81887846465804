import React, { useState, useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CancelSharpIcon from "@material-ui/icons/CancelSharp";
import { makeStyles } from "@material-ui/core/styles";
import Orientation from "./Orientation.png";
import Grow from "@material-ui/core/Grow";
import { OnlineContext } from "./../../context";
import ReactGA from "react-ga4";

import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import { useNavigate } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
//import { useMatomo } from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: 'red',
    height: "100vh",
    "@media screen and (orientation:portrait)": {
      display: "flex",
      flexDirection: "column-reverse",
      height: "100vh",
    },
  },
  iframeGrid: {
    flex: 1,
    "@media screen and (orientation:portrait)": {
      display: "flex",
      flex: 1,
    },
  },
  itemsGrid: {
    "@media screen and (orientation:portrait)": {
      display: "flex",
      justifyContent: "flex-end",
    },
  },
  iframe: {
    width: "100%",
    margin: 0,
    border: "none",
    height: "auto",
    overflowY: "hidden",
    zIndex: 999,
  },
  linearContainer: {
    zIndex: 999,
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "0",
    transform: "translate(-50%, -50%)",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
  },
}));

function PlaySection(props) {
  const classes = useStyles();

  const hide = props.hide;
  const src = props.src;
  const bgColor = props.bgColor;
  const cancelColor = props.cancelColor;
  const navigation = useNavigate();

  const [iframeVisible, setIframeVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  //const { trackEvent } = useMatomo();

  // const [refresh, setRefresh] = useState(0);
  const onlineContext = useContext(OnlineContext);

  useEffect(() => {
    setTimeout(() => {
      setIframeVisible(true);
      setIsLoading(false);
    }, 3000);
  });

  return (
    <Grid
      container
      style={
        hide
          ? {
              backgroundImage: `url(${Orientation})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }
          : { backgroundColor: bgColor }
      }
      className={classes.root}
    >
      <Grid item className={classes.iframeGrid}>
        {isLoading && (
          <Box
            className={classes.linearContainer}
            sx={{
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "rgba(255, 178, 55, 0.8)",
                //
              },
              "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <LinearProgress />
          </Box>
        )}
        <Grow in={onlineContext.online && iframeVisible}>
          <iframe
            style={
              hide
                ? { visibility: "hidden" }
                : {
                    visibility: "visible",
                    margin: 0,
                    border: "none",
                    height: "100%",
                    overflowY: "hidden",
                  }
            }
            title="Game"
            className={classes.iframe}
            src={src}
            key={onlineContext.online ? 1 : 0}
          ></iframe>
        </Grow>
      </Grid>

      <Grid item className={classes.itemsGrid}>
        <IconButton
          //href="/"
          style={{ zIndex: 9999 }}
          onClick={async () => {
            if (
              Capacitor.getPlatform() === "android" ||
              Capacitor.getPlatform() === "ios"
            ) {
              await window.screen.orientation.lock("portrait-primary");
            }

            const name = window.localStorage.getItem("app-name");
            const src = window.localStorage.getItem("src");
            //trackEvent({action:"quit-app",category:"click",name,value:src})
            ReactGA.event({
              category: "click",
              action: "qui-app",
              label: name, // optional
              value: src, // optional, must be a number
              //nonInteraction: true, // optional, true/false
              //transport: "xhr", // optional, beacon/xhr/image
            });
            navigation("/", { replace: true });
          }}
        >
          <CancelSharpIcon style={{ color: cancelColor }} fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default PlaySection;
