import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
//import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import ReactGA from "react-ga4";
import { ConfigProvider } from "antd";

/*const instance = createInstance({
  urlBase: 'https://matomo.kanieba.com/',
  siteId: 1,
  //userId: 'UID76903202', // optional, default value: `undefined`.
  trackerUrl: 'https://matomo.kanieba.com/matomo.php', // optional, default value: `${urlBase}matomo.php`
  //srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
})*/

// const instance = createInstance({
//   urlBase: 'https://matomo.kanieba.com/',
//   siteId: 1,
//   //userId: 'UID76903202', // optional, default value: `undefined`.
//   trackerUrl: 'https://matomo.kanieba.com/matomo.php', // optional, default value: `${urlBase}matomo.php`
//   //srcUrl: 'https://LINK.TO.DOMAIN/tracking.js', // optional, default value: `${urlBase}matomo.js`
//   disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
//   heartBeat: { // optional, enabled by default
//     active: true, // optional, default value: true
//     seconds: 10 // optional, default value: `15
//   },
//   linkTracking: false, // optional, default value: true
//   configurations: { // optional, default value: {}
//     // any valid matomo configuration, all below are optional
//     disableCookies: true,
//     setSecureCookie: true,
//     setRequestMethod: 'POST'
//   }
// })

ReactGA.initialize("G-M5BQ3HFJH5");

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/*<MatomoProvider value={instance}>*/}
    <ConfigProvider theme={{
      components:{
        Button:{
          controlHeight:40
        }
      },
      token:{
        colorPrimary:"#FFC700"
      }
    }}>
    <App />
    </ConfigProvider>
    {/*</MatomoProvider>*/}
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
